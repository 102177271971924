* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #FFFFFF;
  min-height: 100vh;
}

a {
  text-decoration: none;
}

#root {
  min-height: 100vh;
  display: flex;
}

.select-no-legend {
legend {
  display: none !important;
}
}

.no-header {
  padding-top: 0 !important;

header {
  display: none;
}
}

.no-padding {
  padding: 0 !important;
  main {
    padding: 0 !important;
  }
}

@media screen and (max-width: 768px) {
// Need to fix grid on mobile
html, body {
  width: auto!important;
  overflow-x: hidden!important
}
}

// Video-react override

   html .video-react {
.video-react-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

&.video-react-fluid,
&.video-react-16-9,
&.video-react-4-3 {
   height: 100%;
   background-color: rgba(0,0,0,.46);
 }
}
fieldset {
  border-color: #EBECF0 !important;
}

